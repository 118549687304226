import request from '../request'

export const apiresultList = (data) => {
    return request({
        url: `/pay-v2/mall/apply/resultList`,
        method: 'post',
        data
    })
}
export const apiskuList = (data) => {
    return request({
        url: `/pay-v2/mall/apply/skuList`,
        method: 'post',
        data
    })
}

export function orderListAPI(data) {
    return request({
        url: '/other-code/mall/other/order/orderList',
        method: 'post',
        data
    })
}
// 添加
export function addProductAPI(data) {
    return request({
        url: '/pay-v2/mall/apply/add',
        method: 'post',
        data
    })
}
// 按搜索条件添加
export function searchAddProductAPI(data) {
    return request({
        url: '/pay-v2/mall/apply/searchAdd',
        method: 'post',
        data
    })
}
// 清空申请列表
export function deleteAllSku(data) {
    return request({
        url: '/pay-v2/mall/apply/deleteAllSku',
        method: 'post',
        data
    })
}

// 申请列表单个移除
export function deleteSku(params) {
    return request({
        url: `/pay-v2/mall/apply/deleteSku/${params}`,
        method: 'delete',

    })
}
// 申请发票下一步
export function resultInfo(params) {
    return request({
        url: `/pay-v2/mall/apply/resultInfo/${params}`,
        method: 'get',
        params
    })
}
// 申请发票下一步
export function editResultInfo(params) {
    return request({
        url: `/pay-v2/mall/apply/editResultInfo/${params}`,
        method: 'get',
        params
    })
}
// 获取发票列表tab状态
export function apiGetstatistics(params) {
    return request({
        url: `/pay-v2/mall/bill/statistics`,
        method: 'get',
        params
    })
}
// 提交申请
export function addResult(data) {
    return request({
        url: '/pay-v2/mall/apply/addResult',
        method: 'post',
        data
    })
}
// 发票编辑提交申请
export function apiEditAddResult(data) {
    return request({
        url: '/pay-v2/mall/apply/editAddResult',
        method: 'post',
        data
    })
}
// 我的发票列表
export function apiGetList(data) {
    return request({
        url: '/pay-v2/mall/bill/list',
        method: 'post',
        data
    })
}
// 我的发票列表撤销申请
export function apiCancel(params) {
    return request({
        url: `/pay-v2/mall/bill/cancel`,
        method: 'post',
        params,
    })
}
// 我的发票列表详情
export function apiBillListInfo(data) {
    return request({
        url: `/pay-v2/aide/bill/info`,
        method: 'post',
        data
    })
}
// 我的发票列表详情编辑
export function apiBillitemList(data) {
    return request({
        url: `/pay-v2/mall/bill/itemList`,
        method: 'post',
        data
    })
}

// 发票导出
export function apiBillListDownload(data) {
    return request({
        url: `/pay-v2/aide/bill/export/json`,
        method: 'post',
        data
    })
}