<template>
  <div>
    <router-view></router-view>
    <div v-if="$route.path == '/user/applyInvoice'" class="content">
      <div class="top">
        <div class="top-left">
          <div class="title-box">发票列表</div>
          <div class="search-box">
            <el-input placeholder="请输入客户名称"></el-input>
            <el-select placeholder="单据类型"></el-select>
            <el-date-picker
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="card-list">
            <div class="card">
              <div class="card-title">
                <span>成都市云集药业有限公司</span>
                <span class="card-name card-name-orange">销售出库单</span>
              </div>
              <div class="card-info">
                商城订单编号：<span class="black">457568769769</span>
              </div>
              <div  class="card-info">
                单据编号：<span class="black">34645757568(已出库)</span>
              </div>
              <div  class="card-info">
                关联出库单号：<span class="black">34645757568</span>
              </div>
              <div  class="card-info">
                单据生成时间：<span class="black">2023-08-11 12:34:56</span>
              </div>
              <div class="card-total">
                <div>商品总数<span class="black">20</span></div>
                <div>已选商品<span class="green">8</span></div>
                <div>涉及金额<span class="green">2,567.88</span></div>
              </div>
            </div>
          </div>
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="100">
          </el-pagination>
        </div>

        <div class="top-right">
          <div class="title-box">商品列表</div>
          <div class="search-box">
            <el-input placeholder="请输入商品名称"></el-input>
            <el-select placeholder="税率"></el-select>
          </div>
          <el-table :data="tableData" height="880">
            <el-table-column type="selection" width="55" />
            <el-table-column label="商品" width="300">
              <template slot-scope="scope">
                <div class="product-box">
                  <div class="img-box">
                    <img src="../../../assets/images/details/defaultImg.png" alt="">
                  </div>
                  <div class="info-box">
                    <div class="product-name">云南白药</div>
                    <div>
                      <span class="unit">100盒/件</span>
                      <span class="unit">0.26g*100片 (薄膜)</span>
                    </div>
                    <div>厂家：云南白药集团股份有限公司</div>
                    <div>批号：346457568658</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="rate" label="税率" width="80" align="center" />
            <el-table-column prop="stock" label="出库数" width="100" align="center" />
            <el-table-column prop="haved" label="开票中数量(含已开票)" width="160" align="center" />
            <el-table-column prop="num" label="可开票数量" width="120" align="center" />
            <el-table-column prop="price" label="开票价(元)" width="120" align="center" />
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="100">
          </el-pagination>
        </div>
      </div>

      <div class="bottom">
        <div class="next-btn" @click="goConfirm">下一步</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiresultList,
  deleteSku,
  deleteAllSku,
  resultInfo,
  addResult,
} from "@/request/Orders/invoice";
import { shoppingMall } from "@/request/users/users";
export default {
  data() {
    return {
      resultInfoList: {},
      drawer: false,
      billType: 11,
      dialogVisible: false,
      customerOptions: [],
      isAsc: "asc",
      total: 0,
      inputNo: "",
      tableData: [
        {rate: 17, stock: 23, haved: 20, num: 12, price: 8}
      ],
      form: {
        customerAddrId: undefined,
        manufacturer: "",
        orderShipSn: "",
        orderSn: "",
        pageNum: 1,
        pageSize: 10,
        productName: "",
      },
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    //获取列表
    getList() {
      if (this.form.customerAddrId) {
        apiresultList(this.form).then((res) => {
          if (res.code === 200) {
            this.tableData = res.data?.pageData?.rows || [];
            this.total = res.data.pageData?.total;
          }
        });
      }
    },

    // 跳转确认页面
    goConfirm() {
      this.$router.push({
        name: "ApplyInvoiceConfirm",
      });
    }
  },
};
</script>
<style lang="less" scoped>
.black {
  color: #333333;
}
.green {
  color: #26C487;
}
.el-table {
  margin-bottom: 20px;
  :deep(th.el-table__cell){
    background: #F7F8FA;
  }
}
.bottom {
  height: 80px;
  padding: 20px 15px;
  .next-btn {
    float: right;
    width: 102px;
    height: 38px;
    background: #26C487;
    color: #ffffff;
    font-family: 'medium';
    text-align: center;
    line-height: 38px;
    cursor: pointer;
  }
}
.top {
  height: 1070px;
  border-bottom: 1px solid #E9ECF0;
  .top-left {
    float: left;
    width: 357px;
    height: 100%;
    border-right: 1px solid #E9ECF0;
    padding: 40px 20px;
    .el-input {
      width: 160px; 
      margin-right: 35px;
    }
    .el-select {
      width: 120px;
    }
    .el-date-editor {
      width: 100%;
      margin-top: 8px;
      :deep(.el-range__icon) {
        line-height: 3px;
      }
      :deep(.el-range-separator) {
        width: 10%;
        line-height: 25px;
      }
    }
    :deep(.el-input__inner) {
      height: 32px;
    }
  }
  .top-right {
    float: right;
    width: calc(100% - 357px);
    height: 100%;
    padding: 40px 20px;
    .el-input {
      width: 300px;
    }
    .el-select {
      width: 120px;
      float: right;
    }
    :deep(.el-input__inner) {
      height: 32px;
    }
  }
  .title-box {
    font-size: 18px;
    font-family: 'medium';
    color: #333333;
    border-left: 5px solid #26C487;
    padding-left: 12px;
  }
  .search-box {
    margin: 18px 0;
  }

  .card-list {
    height: 850px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .card {
      width: 100%;
      height: 166px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #E9ECF0;
      padding: 10px 16px;
      font-size: 12px;
      font-weight: 400;
      color: #9E9FAB;
      cursor: pointer;
      .card-title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 16px;
      }
      .card-info {
        margin-bottom: 8px;
      }
      .card-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        span {
          font-family: 'medium';
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .card-name {
        display: inline-block;
        width: 74px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
        margin-left: 8px;
      }
      .card-name-orange {
        background: #FFF1DD;
        color: #FFA53C;
      }
    }
  }

  .product-box {
    .img-box {
      display: inline-block;
      width: 86px;
      height: 92px;
      border: 1px solid #E9ECF0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info-box {
      display: inline-block;
      font-size: 12px;
      color: #9E9FAB;
      margin-left: 10px;
      .product-name {
        color: #131313;
        font-size: 14px;
        font-family: 'medium';
      }
      .unit {
        display: inline-block;
        padding: 3px 8px;
        background: #F7F8FA;
        border-radius: 20px;
      }
    }
  }
}
</style>
